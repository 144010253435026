<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view/>
</template>

<script>
import { ref, computed, watch } from 'vue';

export default {
  name: 'App',
  components: {
    
  },
  setup() {
    const tasks = ref([]);

    const addTask = (task) => {
      tasks.value.push(task);
    };

    const removeTask = (taskToRemove) => {
      tasks.value = tasks.value.filter((task) => task !== taskToRemove);
    };

    // Computed property to count the total number of tasks
    const totalTasks = computed(() => tasks.value.length);

    // Watcher to log when tasks change
    watch(tasks, (newTasks) => {
      console.log('Tasks changed:', newTasks);
    });

    return {
      tasks,
      addTask,
      removeTask,
      totalTasks,
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
